/* Base styles */
.main-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.deities-container {
  width: 100%;
  min-height: 100vh;
  padding: 1rem;
  background: linear-gradient(to bottom, #1a1a1a, #0a0a0a);
  display: flex;
  justify-content: center;
}

.deities-grid {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .deities-container {
    padding: 2rem;
  }
}

.deity-card {
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  aspect-ratio: 16/9;
  width: 100%;
}

.deity-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.deity-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.deity-card:hover .deity-image {
  transform: scale(1.05);
} 