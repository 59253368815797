.classes-welcome {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  padding-top: 80px;
  background: transparent;
}

.classes-welcome-content {
  text-align: center;
  padding: 2rem;
  background: transparent;
  position: relative;
  z-index: 1000;
}

.classes-welcome-content h1 {
  font-size: 4.5rem;
  color: white;
  font-weight: 600;
  text-shadow: 0 0 20px rgba(74, 158, 255, 0.5);
  letter-spacing: 2px;
  margin: 0;
  line-height: 1.2;
  background: linear-gradient(45deg, #ffffff, #4a9eff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 200% 200%;
  animation: gradient 8s ease infinite;
  position: relative;
  z-index: 101;
}

.welcome-icon {
  color: #4a9eff;
  margin-bottom: 1rem;
}

.welcome-subtitle {
  font-size: 1.2rem;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 1rem;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
} 