.race-page {
  min-height: 100vh;
  background: #0a0a0a;
  color: white;
}

.race-hero {
  position: relative;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.race-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  z-index: 0;
}

.race-content {
  position: relative;
  z-index: 1;
  text-align: center;
  padding: 0rem 0;
  width: 100%;
  max-width: 100%;
  margin: 0;
  background: rgba(10, 10, 10, 0.7);
  backdrop-filter: blur(5px);
}

.race-content h1 {
  font-size: 4rem;
  margin: 0;
  padding: 0;
  line-height: 1;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .race-content h1 {
    font-size: 2.5rem;
  }
  
  .race-background {
    object-position: center;
  }
}

@media screen and (max-width: 480px) {
  .race-content h1 {
    font-size: 2rem;
  }
}

.race-brief {
  display: none;
}

.race-details {
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
}

.content-section {
  margin-bottom: 3rem;
}

.content-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #fff;
}

.content-section p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
}

.features-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 4rem;
}

.feature-group {
  border-radius: 8px;
  padding: 2rem;
  background: rgba(74, 158, 255, 0.05);
  border: 1px solid rgba(74, 158, 255, 0.1);
}

.trait {
  margin-bottom: 2rem;
}

.trait:last-child {
  margin-bottom: 0;
}

.trait-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.trait-header h3 {
  font-size: 1.5rem;
  color: #4a9eff;
}

.level {
  background: rgba(74, 158, 255, 0.2);
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font-size: 0.9rem;
  color: #4a9eff;
}

.trait p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
}

.spell-list {
  margin-top: 1rem;
}

.spell-level {
  margin: 0.5rem 0;
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
}

.level-number {
  color: #4a9eff;
  font-weight: 600;
}

.additional {
  margin-top: 1rem;
  font-style: italic;
  color: rgba(255, 255, 255, 0.7);
}

.specializations-list,
.benefits-list,
.upgrades-list,
.tools-list {
  list-style: none;
  padding-left: 0;
  margin-top: 1rem;
}

.specializations-list li,
.benefits-list li,
.upgrades-list li,
.tools-list li {
  margin: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
  color: rgba(255, 255, 255, 0.8);
}

.specializations-list li::before,
.benefits-list li::before,
.upgrades-list li::before,
.tools-list li::before {
  content: "•";
  color: #4a9eff;
  position: absolute;
  left: 0;
} 