.content-block {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: rgba(74, 158, 255, 0.05);
  border: 1px solid rgba(74, 158, 255, 0.1);
  border-radius: 8px;
}

.content-block h2 {
  color: #4a9eff;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.content-block p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
}

.traits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.trait {
  padding: 1rem;
  background: rgba(74, 158, 255, 0.05);
  border: 1px solid rgba(74, 158, 255, 0.1);
  border-radius: 8px;
}

.trait-header h3 {
  color: #4a9eff;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.trait p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
}

@media screen and (max-width: 768px) {
  .traits-grid {
    grid-template-columns: 1fr;
  }
  
  .content-block h2 {
    font-size: 1.5rem;
  }
  
  .trait-header h3 {
    font-size: 1.2rem;
  }
} 