.history-container {
    width: 100%;
    height: 100vh;
    background: url('../assets/images/history.jpg') no-repeat center center fixed;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow-y: auto;
}

.history-content {
    background: rgba(0, 0, 0, 0.8);
    padding: 2rem;
    border-radius: 8px;
    max-width: 800px;
    margin: 0 auto;
}

.history-container h1 {
    font-size: 3.5rem;
    margin-bottom: 2rem;
    color: #ffffff;
    text-shadow: 
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000,
        2px 2px 0 #000,
        3px 3px 0 #000,
        4px 4px 8px rgba(0, 0, 0, 0.6);
    font-weight: 800;
    letter-spacing: 2px;
    text-align: center;
}

.history-container p {
    font-size: 1.3rem;
    line-height: 1.8;
    margin-bottom: 1.5rem;
    color: #ffffff;
    text-shadow: 
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000,
        2px 2px 4px rgba(0, 0, 0, 0.6);
    font-weight: 600;
}

/* Custom Scrollbar */
.history-container::-webkit-scrollbar {
    width: 8px;
}

.history-container::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
}

.history-container::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 4px;
}

.history-container::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.4);
}

/* Mobile Specific Styles */
@media screen and (max-width: 768px) {
    .history-container h1 {
        font-size: 2.5rem;
    }
    
    .history-container p {
        font-size: 1.1rem;
    }
} 