.mainContainer {
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.contentContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contentOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 2rem;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sectionContent {
  max-width: 800px;
  margin: 0 auto;
  padding-top: 6rem;
  color: white;
}

.sectionContent p {
  font-size: 1.1rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.sectionContent h2 {
  font-size: 2rem;
  margin: 2rem 0 1rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.sectionContent ul {
  list-style-type: disc;
  margin-left: 2rem;
  margin-bottom: 1.5rem;
  color: white;
}

.sectionContent li {
  margin-bottom: 0.5rem;
  line-height: 1.6;
}

.warning {
  border-left: 4px solid #ff4444;
  color: #ff4444;
}

@media (max-width: 768px) {
  .contentOverlay {
    padding: 1rem;
  }

  .sectionContent {
    padding-top: 4rem;
  }

  .sectionContent p {
    font-size: 1rem;
    padding: 1rem;
  }

  .sectionContent h2 {
    font-size: 1.5rem;
  }
}

.chevron {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  font-size: 6rem;
  color: white;
  cursor: pointer;
  z-index: 100;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  user-select: none;
}

.chevron.left {
  left: 2rem;
}

.chevron.right {
  right: 2rem;
}

.dropdown {
  position: fixed;
  top: 2rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 100;
  text-align: center;
}

.dropdown-title {
  font-size: 2.5rem;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  cursor: pointer;
  margin-bottom: 1rem;
}

.dropdown-menu {
  background: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  padding: 0.5rem;
  min-width: 200px;
}

.dropdown-item {
  color: white;
  padding: 0.75rem 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 4px;
}

.dropdown-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.welcome-page {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  text-align: center;
  background: #0a0a0a;
}

.welcome-content {
  max-width: 800px;
  margin: 0 auto;
}

.content-container {
  background: rgba(0, 0, 0, 0.7);
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
}

.content-container p {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.2rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
}

.content-container p:last-child {
  margin-bottom: 0;
}
  